body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-bold, .h2-floodnav {
  font-weight: bold!IMPORTANT;
}


/* Lower priority hX rules */
.h1-blendin, .h2-blendin, .h3-blendin, .h4-blendin, .h5-blendin, .h6-blendin, .hX-blendin, .h2-floodnav {
  font-size: 1em;
  margin: 0;
  font-weight: inherit;
}

/* Higher priority h1 rules */
.h1-floodnav {
  font-size:  1.1em;
  font-weight: bold;
  margin-bottom: 0;
}

.h2-floodnav {
  font-style: italic;
  font-size: 0.8em;
}

.h1-welcome-to-floodmapper, .h1-dataexplorer{
  font-size: 1.5em; /* Scale as if to h2 */
}

.hX-scale20em {
  font-size:  2.0em;
}

.hX-scale15em, .h1-scale15em  {
  font-size: 1.5em;
}

.hX-scale12em {
  font-size: 1.2em;
}

.hX-scale11em {
  font-size: 1.1em
}

@media (min-width: 768px) {
  .fm-rounded-box-top {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    margin-top: 4px;
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px; 
  }

  .fm-rounded-box-bottom {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-bottom-left-radius: 10px;  
    border-bottom-right-radius: 10px; 
    overflow: hidden;                 
  }
}

.fm-rounded-box-top {
    font-weight: bold;             
    font-size: 1.1em;              
    position: relative;

}
.fm-rounded-box-bottom {
  
}
